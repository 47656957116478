import React from 'react';
import { Box, Typography, Button, CssBaseline, Card, CardContent } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

// Container with gray background and full height
const GrayBackground = styled('div')({
  backgroundColor: '#f5f5f5',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// Styled card with responsive width
const StyledCard = styled(Card)(({ theme }) => ({
  width: '90vw', // Default width for mobile devices
  maxWidth: '500px', // Maximum width for larger screens
  padding: '20px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  [theme.breakpoints.up('md')]: {
    width: '30vw', // Width for medium and larger screens
  },
}));

const ErrorView = ({ error, backpage }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/${backpage}`);
  };

  return (
      <GrayBackground>
        <CssBaseline />
        <StyledCard>
          <CardContent>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h6" color="error">
                Ocorreu um erro
              </Typography>
              <Typography variant="body2" color="textSecondary" mt={2}>
                {error}
              </Typography>
              <Box mt={4}>
                <Button variant="contained" color="primary" onClick={handleBack}>
                  Voltar
                </Button>
              </Box>
            </Box>
          </CardContent>
        </StyledCard>
      </GrayBackground>
  );
};

export default ErrorView;