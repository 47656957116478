import React from 'react';
import { Box, Typography, CircularProgress, CssBaseline, Card, CardContent } from '@mui/material';
import { styled } from '@mui/system';

// Container with gray background and full height
const GrayBackground = styled('div')({
    backgroundColor: '#f5f5f5',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

// Styled card with responsive width
const StyledCard = styled(Card)(({ theme }) => ({
    width: '90vw', // Default width for mobile devices
    maxWidth: '500px', // Maximum width for larger screens
    padding: '20px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    [theme.breakpoints.up('md')]: {
        width: '30vw', // Width for medium and larger screens
    },
}));

const LoadingView = ({ message }) => {
    return (
        <GrayBackground>
            <CssBaseline />
            <StyledCard>
                <CardContent>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <CircularProgress />
                        <Box mt={2}>
                            <Typography variant="h6">
                                {message}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </StyledCard>
        </GrayBackground>
    );
};

export default LoadingView;