import logo from './logo.svg';
import './App.css';
import * as React from 'react';
import {BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom';
import Upload from './pages/Upload';
import Consulta from './pages/Consulta';
import ReactGA from "react-ga4";
import {useEffect} from "react";


function AppContent() {
    const location = useLocation();

    useEffect(() => {
        // Envia um evento de página sempre que a rota mudar
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }, [location]);

    return (
        <Routes>
            <Route path="/" element={<Upload/>}/>
            <Route path="/consulta" element={<Consulta/>}/>
        </Routes>
    );
}

function App() {
    return (
        <Router>
            <AppContent/>
        </Router>
    );
}

export default App;
