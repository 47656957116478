import React, {useRef, useEffect, useState} from 'react';
import './Upload.css';
import {Card, CardContent, TextField, Button, Typography, CssBaseline, Box} from '@mui/material';
import {styled} from '@mui/system';
import {DropzoneArea} from 'mui-file-dropzone';
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import uuid from 'react-uuid'
import ProgressView from './ProgressView'
import LoadingView from './LoadingView'
import ErrorView from './ErrorView'
import ReactGA from "react-ga4";

// Container with gray background and full height
const GrayBackground = styled('div')({
    backgroundColor: '#f5f5f5',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const FullWidthLabel = styled('label')({
    top: 0,
    left: 0,
    width: '100%', // O label ocupa 100% do DropZone
    height: '100%', // O label ocupa 100% do DropZone
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    zIndex: 1, // Garante que o label esteja acima do DropZone
});

// Styled card with responsive width
const StyledCard = styled(Card)(({theme}) => ({
    width: '90vw', // Default width for mobile devices
    maxWidth: '500px', // Maximum width for larger screens
    padding: '20px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    [theme.breakpoints.up('md')]: {
        width: '30vw', // Width for medium and larger screens
    },
}));

// Container for logo and title
const LogoContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
});

// Styled logo
const Logo = styled('img')({
    width: '100px', // Adjust size as needed
    marginBottom: '10px',
});

// Centered Typography
const CenteredTitle = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
});
const DropZone = styled('div')(({isDragging}) => ({
    border: '2px dashed #ccc',
    borderRadius: '8px',
    paddingLeft: '15px',
    paddingRight: '15px',
    textAlign: 'center',
    cursor: 'pointer',
    height: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: isDragging ? '#e0e0e0' : 'transparent',
    '&:hover': {
        borderColor: '#888',
    },
}));


const Upload = () => {
    const navigate = useNavigate()

    const [LoadingUpload, setLoadingUpload] = useState(false)
    const [progress, setProgress] = useState(0)
    const [SelectedFile, setSelectedFile] = useState(null)
    const [LoadingTransferencia, setLoadingTransferencia] = useState(false)
    const [LoadingLogin, setLoadingLogin] = useState(false); // Novo estado para o login
    const [Error, setError] = useState('')
    const [ErrorValidacao, setErrorValidacao] = useState('')
    const [isDragging, setIsDragging] = useState(false);
    const [transferRate, setTransferRate] = useState(0); // Estado para armazenar a taxa de transferência

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const chunkSize = 5 * 1024 * 1024 // 5MB (adjust based on your requirements)
    const totalChunks = Math.ceil(SelectedFile?.size / chunkSize)
    const chunkProgress = 100 / totalChunks
    let chunkNumber = 0
    let start = 0
    let end = 0
    var idGerado = ''
    let Tentativas = 0

    // Create a reference for the username field
    const usernameRef = useRef(null);

    // Focus on the username field when the component mounts
    useEffect(() => {
        if (usernameRef.current) {
            usernameRef.current.focus();
        }
    }, []);

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

    const login = async () => {
        setLoadingLogin(true); // Inicia o loading ao tentar fazer login
        try {
            const response = await axios.post('/api/login', {
                username: username,
                password: password,
            });
            setLoadingLogin(false); // Finaliza o loading após a resposta
            return response;
        } catch (error) {
            setLoadingLogin(false); // Finaliza o loading em caso de erro
            throw error;
        }
    };

    function formatFileSize(sizeInBytes) {
        const units = ['B', 'KB', 'MB', 'GB', 'TB'];
        let size = sizeInBytes;
        let unitIndex = 0;

        while (size >= 1024 && unitIndex < units.length - 1) {
            size /= 1024;
            unitIndex++;
        }

        return `${size.toFixed(1)} ${units[unitIndex]}`;
    }

    function cadastrarTransferencia(pPath) {
        setLoadingTransferencia(true)
        const nomeArquivo = SelectedFile.name;
        const tamanhoArquivo = formatFileSize(SelectedFile.size);
        const urlAtual = window.location.origin;
        const urlConsulta = `${urlAtual}/arquivos/${idGerado}/${nomeArquivo}`;

        axios
            .post('/api/cadastrarTransferencia', {
                uuid_futura: idGerado,
                nome_arquivo: nomeArquivo,
                tamanho_arquivo: tamanhoArquivo,
                caminho: pPath,
                url: urlConsulta,
            })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response);
                    navigate('/consulta?uuid_futura=' + idGerado)
                } else {
                    setError(response.data.erro)
                }
            })
            .catch((error) => {
                if (error.response) {
                    setError(error.response.data.erro)
                } else setError('Ocorreu um erro desconhecido ao cadastrar a transferencia')
            })
            .finally(() => setLoadingTransferencia(false))
    }

    const uploadNextChunk = async () => {
        if (!LoadingUpload) setLoadingUpload(true)
        if (start <= SelectedFile.size) {
            const chunk = SelectedFile.slice(start, end)
            const formData = new FormData()

            formData.append('file', chunk)
            formData.append('chunkNumber', chunkNumber)
            formData.append('totalChunks', totalChunks)
            formData.append('originalname', SelectedFile.name)
            formData.append('fileIdentifier', idGerado)

            const startTime = performance.now();// Capture the start time before upload

            axios({
                method: 'post',
                url: '/api/upload',
                data: formData,
                headers: {'Content-Type': 'multipart/form-data'},
            })
                .then((response) => {
                    const temp = `Chunk ${chunkNumber + 1}/${totalChunks} uploaded successfully`

                    const endTime = performance.now(); // Capture the end time after upload
                    const elapsedTime = (endTime - startTime) / 1000; // Calculate elapsed time in seconds
                    const chunkSizeInMB = chunk.size / (1024 * 1024); // Calculate chunk size in MB
                    const rate = chunkSizeInMB / elapsedTime; // Calculate transfer rate in MB/s
                    setTransferRate(rate.toFixed(2)); // Set transfer rate state


                    setProgress(Number((chunkNumber + 1) * chunkProgress))
                    chunkNumber++
                    Tentativas = 0
                    start = end
                    end = start + chunkSize

                    if (response.status === 200 && response.data.finalizado) {
                        cadastrarTransferencia(response.data.mensagem);
                        ReactGA.event({
                            category: "User",
                            action: "Upload Realizado",
                            label: "Upload"
                        });
                    }

                    uploadNextChunk()
                })
                .catch(async (error) => {
                    if (Tentativas < 999999) {
                        console.log('Tentando' + Tentativas)
                        Tentativas++
                        await delay(1000)
                        uploadNextChunk()
                    } else {
                        setLoadingUpload(false)
                        Tentativas = 0

                        if (error.response) {
                            setError(
                                'Ocorreu um erro ao realizar o upload do arquivo: ' + error.response.data.erro,
                            )
                        } else setError('Ocorreu um erro desconhecido ao realizar o upload do arquivo')
                    }
                })

        } else {
            setProgress(100)
            setSelectedFile(null)
            setLoadingUpload(false)
        }
    }

    const handleUpload = async () => {
        try {
            if (!SelectedFile) {
                setErrorValidacao('Selecione um arquivo para enviar');
                return; // Interrompe a execução da função
            }

            if ((!username) || (!password)) {
                setErrorValidacao('Preencha o usuário/senha para enviar');
                return; // Interrompe a execução da função
            }

            setLoadingLogin(true); // Inicia o loading ao tentar fazer login

            const response = await login();
            idGerado = uuid()

            if (response.status === 200) {
                setLoadingLogin(false); // Finaliza o loading após o login
                uploadNextChunk();
            } else {
                setLoadingLogin(false); // Finaliza o loading em caso de erro
                setErrorValidacao('Usuário ou senha incorretos');
            }
        } catch (error) {
            setLoadingLogin(false); // Finaliza o loading em caso de erro
            setErrorValidacao('Usuário ou senha incorretos');
        }
    }

    const handleFileDrop = (event) => {
        event.preventDefault();
        setIsDragging(false);
        const files = event.dataTransfer.files;
        if (files && files.length > 0) {
            setSelectedFile(files[0]);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleFileChange = (event) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            setSelectedFile(files[0]);
        }
    };

    function FormularioRequisicao() {
        return (
            <GrayBackground>
                <CssBaseline/>
                <StyledCard>
                    <CardContent>
                        <LogoContainer>
                            <Logo src="./Futura.png" alt="Logo"/>
                        </LogoContainer>
                        <CenteredTitle>
                            <Typography variant="h5" component="h2" gutterBottom>
                                Futura Transferência
                            </Typography>
                        </CenteredTitle>
                        <Box mb={2}>
                            <TextField
                                fullWidth
                                label="Usuário"
                                variant="outlined"
                                margin="normal"
                                inputProps={{
                                    style: {
                                        textTransform: 'uppercase', // Transform text to uppercase
                                    },
                                }}
                                inputRef={usernameRef}
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </Box>
                        <Box mb={2}>
                            <TextField
                                fullWidth
                                type="password"
                                label="Senha"
                                variant="outlined"
                                margin="normal"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Box>

                        <DropZone
                            onDrop={handleFileDrop}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            isDragging={isDragging}
                        >
                            <input
                                type="file"
                                onChange={handleFileChange}
                                style={{display: 'none'}}
                                id="fileInput"
                            />
                            <FullWidthLabel htmlFor="fileInput" style={{cursor: 'pointer'}}>
                                {SelectedFile ? SelectedFile.name : 'Arraste e solte o arquivo, ou clique aqui para selecionar o arquivo manualmente'}
                            </FullWidthLabel>
                        </DropZone>
                        <Box mt={2}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleUpload}
                            >
                                Enviar
                            </Button>
                        </Box>
                        {ErrorValidacao && (
                            <Box mt={2}>
                                <Typography color="error">{ErrorValidacao}</Typography>
                            </Box>
                        )}
                    </CardContent>
                </StyledCard>
            </GrayBackground>
        );
    }

    if (LoadingLogin) return <LoadingView message="Verificando suas credenciais..."/>;
    if (LoadingTransferencia) return <LoadingView message="Cadastrando transferência..."/>;
    else if (LoadingUpload) return <ProgressView progress={Math.round(progress)} transferRate={transferRate}/>;
    else if (Error) return <ErrorView error={Error} backpage={'upload'}/>;
    else return FormularioRequisicao();
};

export default Upload;