import React, {useEffect, useState} from 'react';
import {Card, CardContent, Typography, Button, CssBaseline, Box, CircularProgress, Alert} from '@mui/material';
import {styled} from '@mui/system';
import axios from 'axios';
import {useSearchParams, useNavigate} from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {format, parseISO} from 'date-fns';
import {
    WhatsappShareButton,
    EmailShareButton,
    FacebookShareButton,
    WhatsappIcon,
    EmailIcon,
    FacebookIcon
} from 'react-share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const GrayBackground = styled('div')({
    backgroundColor: '#f5f5f5',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const StyledCard = styled(Card)(({theme}) => ({
    width: '90vw',
    maxWidth: '500px',
    padding: '20px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    [theme.breakpoints.up('md')]: {
        width: '30vw',
    },
}));

const LogoContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
});

const Logo = styled('img')({
    width: '100px',
    marginBottom: '10px',
});

const CenteredTitle = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
});

const DetailRow = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '10px',
});

const NoDataContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '150px',
    textAlign: 'center',
});

const ShareContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '20px',
});

const Consulta = () => {
    const [searchParams] = useSearchParams();
    const uuid_futura = searchParams.get('uuid_futura');
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [alertOpen, setAlertOpen] = useState(false);
    const currentUrl = window.location.href;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/api/retornaTransferencia?uuid_futura=${uuid_futura}`);
                if (response.status === 200 && response.data && Object.keys(response.data).length > 0) {
                    setError(null);
                    setData(response.data);

                } else {
                    setError('Nenhum dado encontrado para a chave fornecida.');
                    setData(null);
                }

            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Não foi possível recuperar os dados. Por favor, tente novamente.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [uuid_futura]);

    const handleDownload = () => {
        if (data && data.url) {
            window.open(data.url, '_blank');
        }
    };

    const handleBack = () => {
        navigate('/');
    };

    const handleCopyToClipboard = () => {

        navigator.clipboard.writeText(currentUrl).then(() => {
            setAlertOpen(true);
            setTimeout(() => setAlertOpen(false), 2000); // Fecha o alerta após 2 segundos
        });
    };


    return (
        <GrayBackground>
            <CssBaseline/>
            <StyledCard>
                <CardContent>
                    {loading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                            <CircularProgress/>
                        </Box>
                    ) : error || !data ? (
                        <NoDataContainer>
                            <ErrorOutlineIcon color="error" style={{fontSize: 60, marginBottom: 10}}/>
                            <Typography variant="h6" color="textSecondary">
                                Nenhum dado encontrado
                            </Typography>
                            <Typography variant="body1" color="textSecondary">
                                Não foi possível recuperar os dados. Por favor, verifique a chave e tente novamente.
                            </Typography>
                        </NoDataContainer>
                    ) : (
                        <>
                            <LogoContainer>
                                <Logo src="./Futura.png" alt="Logo"/>
                            </LogoContainer>
                            <CenteredTitle>
                                <Typography variant="h5" component="h2" gutterBottom>
                                    Detalhes do Arquivo
                                </Typography>
                            </CenteredTitle>
                            <DetailRow>
                                <Typography variant="subtitle1"><strong>Nome do Arquivo:</strong></Typography>
                                <Typography variant="subtitle1">{data.nome_arquivo}</Typography>
                            </DetailRow>
                            <DetailRow>
                                <Typography variant="subtitle1"><strong>Usuário:</strong></Typography>
                                <Typography variant="subtitle1">{data.lkp_usuario}</Typography>
                            </DetailRow>
                            <DetailRow>
                                <Typography variant="subtitle1"><strong>Tamanho do Arquivo:</strong></Typography>
                                <Typography variant="subtitle1">{data.tamanho_arquivo}</Typography>
                            </DetailRow>
                            <DetailRow>
                                <Typography variant="subtitle1"><strong>Data de Envio:</strong></Typography>
                                <Typography
                                    variant="subtitle1">{format(parseISO(data.data_envio), 'dd/MM/yyyy')}</Typography>
                            </DetailRow>
                            {data.status === 0 ? (
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={handleDownload}
                                >
                                    Download
                                </Button>
                            ) : (
                                <Typography variant="subtitle1" color="error" align="center">
                                    Arquivo expirado
                                </Typography>
                            )}
                            <Button
                                fullWidth
                                variant="outlined"
                                color="primary"
                                onClick={handleBack}
                                style={{marginTop: '10px'}}
                            >
                                Voltar
                            </Button>
                            <ShareContainer>
                                <WhatsappShareButton url={currentUrl}>
                                    <WhatsappIcon size={32} round={true}/>
                                </WhatsappShareButton>
                                <EmailShareButton url={currentUrl}>
                                    <EmailIcon size={32} round={true}/>
                                </EmailShareButton>
                                <Button onClick={handleCopyToClipboard} style={{
                                    width: 32,
                                    minWidth: 32, // Evita que o botão aumente de tamanho
                                    padding: 0,   // Remove o padding para garantir o tamanho correto
                                }}>
                                    <ContentCopyIcon/>
                                </Button>
                            </ShareContainer>
                            {alertOpen && (
                                <Alert severity="success" style={{marginTop: '10px'}}>
                                    Link copiado para a área de transferência
                                </Alert>
                            )}
                        </>
                    )}
                </CardContent>
            </StyledCard>
        </GrayBackground>
    )
        ;
};

export default Consulta;